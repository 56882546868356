import { UseGeneratePdf } from "../../utils/generatePdf";
import ApiService from '../../domain/services/ApiService';
import { ArrayToQParams } from '../../application/utils/arrayToQParams';

const UseCaseGeneratePdf = {
    search: async (args) => {
        console.clear();
        console.log("UseCaseGeneratePdf::search", args);

        //            setIsLoadingstatus(true);

        const apiService = new ApiService(process.env.REACT_APP_HOST_API_CLIENT); // Substitua pela sua URL       
        const token = localStorage.getItem('authToken');
        apiService.setAuthToken(token);
        let mesRef = args.workSpace.folha_pgto.mes_competencia;
        let colaborador_id = args.data.colaborador_id;

        if (args.workSpace.folha_pgto.ciclo == 1)
            mesRef = mesRef - 1;

        const query = {
            where: [["A.assinatura_contrato_dt", ">=", `'20${args.workSpace.folha_pgto.ano_competencia}-${mesRef}-01'`], ["A.proposta_status_id", "=", 1890], ["E.colaborador_id", "=", 356]]
        }
        try {
            //console.log("~~~~~~~~~~~~~~ ", '/folha_pgto' + `${ArrayToQParams(query)}&method=relatorio&search=true&page=1&left_join=true`);

//const urlQ = '/folha_pgto' + `?page=1&left_join=true&search=true&rows_count_total=true&limit=50&method=relatorio&w=A.dt_efetivada|BETWEEN|[20${args.workSpace.folha_pgto.ano_competencia}-${mesRef}-01'~E.colaborador_id|=|${colaborador_id}`;
const urlQ = '/folha_pgto' + `?page=1&left_join=true&search=true&rows_count_total=true&limit=50&method=relatorio&w=A.dt_efetivada|BETWEEN|2024-11-16,2024-11-30~E.colaborador_id|=|${colaborador_id}`;
            const result = await apiService.get(urlQ);
            console.log(urlQ,result);
            //setListstatus(result.result);
            //setIsLoadingstatus(false);

            let content = {
                "header": args.workSpace.folha_pgto,
                "body_header": args.workSpace.folha_pgto,
                "body_content": result['result'],
                "body_footer": {
                    "total": "args.valor_total"
                },
                "footer": ""
            }

            let configs = {
                "template": "R185C.ejs",
                "outputFilename": "Relatorio de comissões - "+args.data.colaborador_name,
                "api_pdf":`https://pdf-beta.singularis.dev.br/generateReport?download=true`,
                "paper": {
                    "format": "A4",
                    "orientation": "landscape",
                    "zoomFactor": "2.05",
                    "height": "210mm",
                    "width": "290mm"
                }
            }
            const useGeneratePdf =  await UseGeneratePdf({ content: content, configs: configs })
            console.log("useGeneratePdf",useGeneratePdf);
            return useGeneratePdf
            
        } catch (error) {
            console.error("Erro ao buscar dados:", error.message);
        } /* finally {
                setIsLoadingstatus(false);
            } */



    },
    GeneratePdf: async (args) => {
        console.clear();
        console.log("GeneratePdf::args", args);
        args.workSpace.folha_pgto.emmiter_user_name = args.session.USER_SHORT_NAME
        let content = {
            "header": args.workSpace.folha_pgto,
            "body_header": args.workSpace.folha_pgto,
            "body_content": args.data,
            "body_footer": {
                "total": args.valor_total
            },
            "footer": ""
        }

        let configs = {
            "template": "folha_pgto.ejs",
            "outputFilename": args.workSpace.folha_pgto.folha_pgto_name.toLowerCase(),
            "api_pdf":`https://pdf-beta.singularis.dev.br/generateReport?download=true`,

        }

        UseGeneratePdf({ content: content, configs: configs })
    }
}

export default UseCaseGeneratePdf;

