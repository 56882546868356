import React, { useEffect, useState, useRef, useContext } from 'react'

import CustomDropdown from './CustomDropdown2';
import useRead from '../domain/services/hookRead';
import { ReadSearch } from '../domain/services/readSearch';

const SelectPercentual = ({ callback,field, formik, loading, data, options, optionSelected, listener, required, onBlurHandler, labelField, placeholder, label, ref, additionalName = null }) => {
    const [liststatus, isLoadingstatus, isErrorstatus] = useRead({
        table: "db_register.tb_status",
        src: 'status',
        where: [["status_id", "!=", "0"], ["screen_id", "=", data.screen_id]],
        orderBy: "status_name asc",
        limit: "50"
    }, ReadSearch);

const ok =[{percentual:"1",label:"1%"},{percentual:"2",label:"2%"},{percentual:"3",label:"3%"},{percentual:"4",label:"4%"}];
console.log("list Percentual: ",ok);

    return (
        <CustomDropdown
            field={field}
            label={label ?? "Status da turma"}
            options={[ok, 'd', 'label', 'percentual']}
            labelField="name"
            additionalName={additionalName}
            loading={isLoadingstatus}
            //listener={onListener}
            optionSelected='label'
            required={true}
            //onBlurHandler={()=> callback()}
            onChange={callback}
            placeholder="Status da turma"
            formik={formik}
        />

    );
}

export default SelectPercentual; 