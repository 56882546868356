import axios from 'axios';
import SingHelper from './SingHelper';

export const UseGeneratePdf = async (args)=>{     
    
    console.log("args",args);
     /* if(!args.configs.api_pdf)
        args.configs.api_pdf=`https://pdf.singularis.dev.br/generateReport?download=true`; */
        let data = {
            "page": null,
            "per_page": null,
            "total": null,
            "total_pages": null,                        
            "content": args.content,
            "support": {
                "url": "https://reqres.in/#support-heading2",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            },
            "configs": {
                "template": args.configs.template,
                "api_pdf": args.configs.api_pdf,
                "type": "pdf",
                "outputFilename": `${SingHelper.stringClear(args.configs.outputFilename)}`,
                "qrcodeOrigin": "`${el.cpf}${el.ingresso_id}`",
                "event_name": "Nome do evento",
                "date": "`06/11/2023`",
                "locale": "`Imperatriz-MA`",
                "paper": args.configs.paper
            }
        }
        console.log(":::: DATA ENVIADA A API PDF :::: ",data);
       return  await axios
            .post(args.configs.api_pdf, data, {
            //.post(`${process.env.REACT_APP_SERVER_PDF}/generateReport?download=true`, data, {
                responseType: "blob", // this is important!
                headers: { Authorization: "sometoken" },
            })
            .then(response => {
                //console.log("generatePdf", response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${data.configs.outputFilename}.${data.configs.type}`);
                document.body.appendChild(link);
                link.click();
                return true;
            })
            .catch(r => {
                 console.log("Houve algum erro");
            })
        //http://localhost:4002/generateReport?download=true
    }